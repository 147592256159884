.contact-section {
    margin: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-section__headline {
    color: #000;
    text-align: center;
    font-size: 2.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

/* Styles for mobile */
@media screen and (max-width: 440px) {
    .contact-section__headline {
        font-size: 1.75rem;
        margin-bottom: 0.5rem;
    }
}

.contact-section__description {
    color: #495057;
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 35rem;
}

/* Styles for mobile */
@media screen and (max-width: 440px) {
    .contact-section__description {
        font-size: 0.975rem;
        margin-bottom: 1.75rem;
    }
}

.contact-section__form-container {
    max-width: 37rem;
    width: 100%;
}

.contact-section__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-section__form__input-row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
}

/* Styles for mobile */
@media screen and (max-width: 440px) {
    .contact-section__form__input-row {
        flex-direction: column;
        align-items: center;
    }
}

.contact-section__form__input {
    margin: 1rem;
    flex: 1;
}

/* Styles for mobile */
@media screen and (max-width: 440px) {
    .contact-section__form__input {
        margin: 0 0 1.5rem 0;
        width: 100%;
        font-size: 0.875rem;
    }
}

.contact-section__form__input input {
    width: 100%;
}

.contact-section__form__input-area {
    margin: 1rem;
    width: 100%;
}

.contact-section__form__input-area textarea {
    width: 100%;
}

.contact-section__form__checkbox-row {
    color: #64748B;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1.5rem;
    margin-top: 0.5rem;
}

/* Styles for mobile */
@media screen and (max-width: 440px) {
    .contact-section__form__checkbox-row {
        font-size: smaller;
    }
}

.contact-section__form__checkbox {
    margin-right: 1rem;
}

/* Update styles for PrimeReact Dropdown */
.contact-section__form__input .p-dropdown {
    width: 100%;
}

span.p-float-label.contact-section__form__input{
    height: 2.80rem;
}

/* Match input styling exactly */
.contact-section__form__input .p-dropdown .p-dropdown-label {
    padding: 0.75rem 0.75rem 0.75rem;
    height: 2.85rem;
}

/* Adjust the trigger button alignment */
.contact-section__form__input .p-dropdown .p-dropdown-trigger {
    align-items: center;
    padding-right: 0.75rem;
}