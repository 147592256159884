.login-page__form-container__title-emoji {
    margin-top: 3.5rem;
    margin-bottom: 0;
}

.login-page__form-container__description {
    color: #495057;
    text-align: center;
}

.login-page__form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem
}

.login-page__form {
    max-width: 32rem;
    width: 100%;
}

.login-page__form__input-row {
    margin-top: 1.75rem;
    margin-bottom: 0.85rem;
}

.login-page__form__input {
    width: 100%;
}

.login-page__form__input input {
    width: 100%;
}

.login-page__form__forgot-password {
    margin-top: 0;
    text-align: end;
    font-size: 0.95rem;
}

.login-page__form__submit {
    width: 100%;
    margin-bottom: 1.5rem;
    margin-top: 0.5rem;
}

.login-page__form__small-text {
    color: #64748B;
    font-size: 0.85rem;
}

.login-page__form__text-link {
    text-decoration: none;
    color: var(--primary-color);
    cursor: pointer !important;
    background-color: transparent;
    border: 0;
}