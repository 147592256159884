.navbar {
    width: 100%;
    background: #fff;
    display: flex;
    flex-direction: row;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    justify-content: space-between;
}

.navbar__left {
    display: flex;
    justify-content: center;
    align-items: center;
}
.navbar__left img {
    height: 1.5rem;
}

.navbar__right {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.navbar__right .p-button {
    margin-left: 0.75rem;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
}

.navbar__right .p-button-icon-only {
    margin-left: 0.75rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
}