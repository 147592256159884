.hero-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: 2.7rem;
    margin-bottom: 3.5rem;
    max-width: 68rem;
    width: 100%;
}

.hero-section__title {
    color: #000;
    text-align: center;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    max-width: 55rem;
    margin-top: 8.125rem;
    margin-left: 1rem;
    margin-right: 1rem;
}
.hero-section__title__highlight {
    /* background: linear-gradient(90deg, #4381CA, #A4AD6F, #B6A964, #D6C73C); */
    background: linear-gradient(90deg, #6893B2, #A4AD6F 60%, #B6A964 66%, #D3C05C);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}

/* Styles for tablet */
@media screen and (max-width: 768px) {
    .hero-section__title {
        font-size: 2rem;
        margin-top: 6.125rem;
    }
}

.hero-section__description {
    color: #495057;
    text-align: center;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 37rem;
    margin-bottom: 4rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

/* Styles for tablet */
@media screen and (max-width: 768px) {
    .hero-section__description {
        font-size: 1.125rem;
        margin-bottom: 3rem;
    }
}

.hero-section__cta {
    background: radial-gradient(170% 500% at -15% 150.68%, #B1E540 0%, #1F6518 100%);
    border: 0;
    padding: 0.75rem 1.2rem;
    border-radius: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-weight: 600;
    box-sizing: border-box;
    margin-bottom: 5rem;
    text-decoration: none;
}

.hero-section__cta:hover {
    scale: 1.02;
    cursor: pointer;
}

.hero-section__cta__icon {
    margin-left: 0.5rem;
}

.hero-section__2d-image {
    position: absolute;
    width: 253px;
    height: 253px;
    top: 180px;
    left: 51px;
    z-index: -1;
}

/* Styles for tablet */
@media screen and (max-width: 768px) {
    .hero-section__2d-image {
        width: 30%;
        height: auto;
        aspect-ratio: 1/1;
        top: 150px;
        left: 30px;
    }
}

.hero-section__3d-image {
    position: absolute;
    width: 351px;
    height: 314px;
    top: 161px;
    right: 15px;
    z-index: -1;
}

/* Styles for tablet */
@media screen and (max-width: 768px) {
    .hero-section__3d-image {
        width: 38%;
        height: auto;
        aspect-ratio: 1/1;
        top: 130px;
        right: 15px;
    }
}

.hero-section__blob-1 {
    position: absolute;
    width: 448.217px;
    height: 365.791px;
    transform: rotate(167.231deg);
    left: 108px;
    top: 15px;
    border-radius: 448.217px;
    background: radial-gradient(50.00% 50.00% at 50.00% 50.00%, rgba(202, 224, 69, 0.06) 0%, rgba(212, 210, 197, 0.05) 62.50%, rgba(217, 217, 217, 0.00) 100%);
    z-index: -1;
}

/* Styles for tablet */
@media screen and (max-width: 768px) {
    .hero-section__blob-1 {
        width: 40%;
        height: auto;
        aspect-ratio: 448 / 365;
        top: 50px;
        left: 20px;
    }
}

.hero-section__blob-2 {
    position: absolute;
    width: 355.367px;
    height: 217.736px;
    transform: rotate(-130.712deg);
    right: 191px;
    top: 144px;
    border-radius: 355.367px;
    background: radial-gradient(50.00% 50.00% at 50.00% 50.00%, rgba(184, 224, 69, 0.06) 0%, rgba(212, 210, 197, 0.05) 62.50%, rgba(217, 217, 217, 0.00) 100%);
}

/* Styles for tablet */
@media screen and (max-width: 768px) {
    .hero-section__blob-2 {
        width: 40%;
        height: auto;
        aspect-ratio: 355 / 217;
        top: 130px;
        right: 80px;
    }
}