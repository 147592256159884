.video-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2.875rem;
    border-radius: 15px;    
    /* background: rgba(237, 242, 247, 0.25); */
    padding: 1rem;
    padding-top: 1.5rem;
    padding-bottom: 3rem;
    max-width: 64rem;
    width: 100%;
}

/* Styles for large screen */
@media screen and (min-width: 1200px) {
    .video-section {
        max-width: 1300px;
    }
}

.video-section__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    width: 100%;
}

/* Styles for mobile */
@media screen and (max-width: 440px) {
    .video-section__wrapper {
        padding: 0.5rem;
    }
}

.video-section__headline {
    color: #000;
    text-align: center;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 2.625rem;
    padding: 0 1rem;
}

/* Styles for mobile */
@media screen and (max-width: 440px) {
    .video-section__headline {
        font-size: 1.5rem;
    }
}

.video-section__video-container {
    position: relative;
    width: 90%;
    aspect-ratio: 16/9;
}

.video-section__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
}