.footer-section-container {
    width: 100%;
    padding: 0 2rem;
    max-width: 64rem;
}

/* Styles for big screen */
@media screen and (min-width: 1200px) {
    .footer-section-container {
        max-width: 1300px;
    }
}

/* Styles for tablet */
@media screen and (max-width: 768px) {
    .footer-section-container {
        padding: 0 1rem;
    }
}

.footer-section {
    border-top: 1px solid #9FA9B7;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
    width: 100%;
}

/* Styles for tablet */
@media screen and (max-width: 768px) {
    .footer-section {
        padding: 1rem 0;
    }
}

.footer-section__left {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.footer-section__left img {
    height: 1.25rem;
}

/* Styles for tablet */
@media screen and (max-width: 768px) {
    .footer-section__left img {
        height: 1rem;
    }
}

.footer-section__right {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.footer-section__right__text {
    color: #495057;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
}

/* Styles for tablet */
@media screen and (max-width: 768px) {
    .footer-section__right__text {
        font-size: 0.875rem;
    }
}

/* Styles for mobile */
@media screen and (max-width: 440px) {
    .footer-section__right__text {
        font-size: 0.75rem;
    }
}